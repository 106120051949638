<template>
  <div class="loveDonate">
    <!-- <div class="loveDonate-t">
      <img src="./img/scan.png" alt="" />
    </div> -->
    <div class="loveDonate-c">
      <div class="loveDonate-cl" @click="toDonate">
        <img src="./img/mytodontate.png" alt="" />
      </div>
      <div class="loveDonate-cr">
        <div class="loveDonate-crt" @click="toDonated">
          <img src="./img/todounate.png" alt="" />
        </div>
        <div class="loveDonate-crb" @click="toDonateList">
          <img src="./img/mydonate.png" alt="" />
        </div>
      </div>
    </div>
    <div class="loveDonate-b">
      <p>说明：</p>
      <p>感谢您对我们工作的支持与关注！</p>
      <p>您的捐助将帮助更多的困难人群。</p>
      <p>您的合理受捐请求将尽可能为您匹配。</p>
      <p>请严肃对待募捐事项。</p>
    </div>
  </div>
</template>

<script>
import { toRegister, gloabalCount } from "@/utils/common.js";
import {} from "./api.js";
var moment = require("moment");
export default {
  name: "loveDonate",
  data() {
    return {};
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {},
  mounted() {},
  methods: {
    toDonateList() {
      this.$router.push({
        name: "loveDonateList",
      });
    },
    toDonated() {
      this.$router.push({
        name: "todonateSubmit",
      });
    },
    toDonate() {
      this.$router.push({
        name: "donateSubmit",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.loveDonate {
  min-height: 100vh;
  padding: 98px 30px;
  box-sizing: border-box;
  position: relative;
  .loveDonate-t {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 38px;
    right: 50px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .loveDonate-c {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .loveDonate-cl {
      width: 340px;
      height: 320px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .loveDonate-cr {
      .loveDonate-crt {
        margin-bottom: 20px;
      }
      .loveDonate-crt,
      .loveDonate-crb {
        width: 330px;
        height: 150px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .loveDonate-b {
    margin-top: 40px;
    font-weight: 400;
    font-size: 28px;
    color: rgba(0, 0, 0, 0.5);
    line-height: 40px;
  }
}
</style>
